import React, { Component } from 'react';

import { connect } from "react-redux";
import { Button, ButtonGroup, LinearProgress } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
//import LoginButton from "../../components/authlogin/authlogin2";
import LoginButton from "../../components/authlogin/authloginColaTwo";

import { getFromSession } from '../../controller/session';

import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Modal from '@material-ui/core/Modal';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ImageIcon from "@material-ui/icons/Image";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CircularProgress from "@material-ui/core/CircularProgress";
import DescriptionIcon from "@material-ui/icons/Description";
import CustomForm from "../../components/materialform/customform";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DataTableWithPaginator from '../../components/tableWithPaginator/tablePaginator';
import { getBandejaAdmin, getConsultaPaginada, getDataAnexostable, getDataRegVivienda, getDynamicOptions, getErrorTryCatch, getMessageAlert, getMessageConfirmation, getOpenAnexosTable, getOpenAnulationTable, getOpenDevolutionTable, getOpenFormBandeja, getOpenModalCharged, getOpenMondalTryCatch, getSessionValidated, getSnackBar } from './selectors';
import { ACTIONS_OF_APROVE, ANULACION_FORM, ANULACION_FORM_NAME, ANULAR_POSTULATION_FROM_TABLE, CLOSE_MODALES, DATA_POR_VALIDAR, DEVOLUCIONES_FORM, DEVOLUCIONES_FORM_NAME, DEVOLVER_POSTULATION_FROM_TABLE, GESTIONA_MODAL_TRY_CATCH, HEADER_TABLE_PAGINATOR_EMPRESAS, LOAD_CATALOG_BANDEJA, OPEN_MODAL_ANULATION_TABLE, OPEN_MODAL_DEVOLUTION_TABLE, OPEN_SEE_FILES_UNIFIED, PAGINAR_DATA_REG_EMPRESAS, RETURN_HOME_BANDEJA, SAVED_ACTIONS_VALIDATION, SEARCH_FORM_FROM_TABLE, TITLE_TABLE_PAGINATOR_EMPRESAS, VALIDATE_USER_TYPE, VISUALIZE_ANEXOS_FROM_TABLE } from './constants';
import { ComponetBandeja } from './componetRevision';

class BandejaComponent extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentDidMount() {
        const { loadCatalog } = this.props;
        loadCatalog();
    }

    componentDidUpdate() {

    }

    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={2} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    modalTryCatch(message) {
        const { closeModalTryCatch } = this.props;

        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="messageSucess">
                    <React.Fragment>
                        <Alert className="alert danger">
                            {message}
                        </Alert>
                    </React.Fragment>


                    <div>
                        <Button
                            className='buttonFinalizar'
                            variant="text"
                            color="default"
                            onClick={() => closeModalTryCatch()}
                        >
                            Aceptar
                        </Button>
                    </div>


                </div>
            </Modal>
        );
    }

    openModalAnexosTable() {
        const { openAnexosTable, handleSeeAnexo, dataAnexostable } = this.props;

        return (
            <Dialog
                className="modal-anexos-table"
                open
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <ButtonGroup className="button-closed-modal" color="primary" aria-label="primary button group">
                    <Button
                        onClick={() => handleSeeAnexo({ destroy: true })}
                    >
                        X
                    </Button>

                </ButtonGroup>
                <DialogTitle id="scroll-dialog-title">ANEXOS SOLICITUD</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                    >
                        <div className="container-form-anexos">
                            <TableContainer
                                component={Paper}
                                className={`tableSeeAnexo table-Container-2`}
                            >
                                <Table className={"classes.table"} aria-label="simple table">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                        {openAnexosTable &&
                                            openAnexosTable.map((row) => (
                                                <TableRow>
                                                    <TableCell align="right">
                                                        <Button
                                                            color={"secondary"}
                                                            type={"submit"}
                                                            className={"buttonSeeAnexos"}
                                                            startIcon={
                                                                row.type.includes("pdf") ? (
                                                                    <DescriptionIcon />
                                                                ) : (
                                                                    <ImageIcon />
                                                                )
                                                            }
                                                            onClick={(event) => handleSeeAnexo({ event, row, open: true })}
                                                        >
                                                            {row.nameDoc ? row.nameDoc : row.nameArchivo}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <div className="container-anexos-form">
                    {openSeeAnexos !== false ? modalSeeAdjuntos() : ""}
                </div> */}
                        </div>
                    </DialogContentText>
                    {dataAnexostable && <React.Fragment>

                        <div className="modalSeeAdjuntos">
                            <div className="Anexo">
                                {dataAnexostable.type.includes("pdf") ? (
                                    <embed
                                        className="objAnexo"
                                        src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${dataAnexostable.refFile}`}
                                        width="100%"
                                    />
                                ) : (
                                    <img
                                        className="objAnexo"
                                        src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${dataAnexostable.refFile}`}
                                    />
                                )}
                            </div>
                        </div>
                        <ButtonGroup className="buttonCloseAnexo" color="primary" aria-label="primary button group">
                            <Button
                                onClick={() => handleSeeAnexo({ open: false })}
                            >
                                Cerrar
                            </Button>
                        </ButtonGroup>
                    </React.Fragment>
                    }

                </DialogContent>
                {/* <DialogActions>
                    

                </DialogActions> */}
            </Dialog>
        );

    }

    openModalDevolucion() {
        const { messageAlert, closeDevolucionTable, openDevolutionTable, openModalCharged, dynamicOptions, actionsButtonsAprove } = this.props;

        return (
            <Dialog
                className="modal-anexos-table"
                open
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <ButtonGroup className="button-closed-modal" color="primary" aria-label="primary button group">
                    <Button
                        onClick={() => closeDevolucionTable({ destroy: true })}
                    >
                        X
                    </Button>

                </ButtonGroup>
                <DialogTitle id="scroll-dialog-title">DEVOLUCION</DialogTitle>
                <DialogContent>
                    {messageAlert && this.modalSubmitValid(this.props)}
                    {openModalCharged && this.modalEsperaCharged(this.props)}
                    <DialogContentText
                        id="scroll-dialog-description"
                    >
                        <React.Fragment>
                            <CustomForm
                                submitable={false}
                                items={DEVOLUCIONES_FORM}
                                formName={DEVOLUCIONES_FORM_NAME}
                                className="people-form"
                                dynamicOptions={dynamicOptions}
                            />
                        </React.Fragment>
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="anulation-devolution" color="primary" aria-label="primary button group">
                        <Button
                            onClick={() => closeDevolucionTable({ destroy: true })}
                        >
                            Cancelar
                        </Button>

                        <Button
                            onClick={() => actionsButtonsAprove({ type: "DEVOLVER", from: "table", data: openDevolutionTable })}
                        >
                            Devolver
                        </Button>
                    </ButtonGroup>

                </DialogActions>
            </Dialog>
        );

    }

    openModalAnulacion() {
        const { messageAlert, openAnulationTable, closeAnulacionTable, dynamicOptions, actionsButtonsAprove, openModalCharged } = this.props;

        return (
            <Dialog
                className="modal-anexos-table"
                open
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <ButtonGroup className="button-closed-modal" color="primary" aria-label="primary button group">
                    <Button
                        onClick={() => closeAnulacionTable({ destroy: true })}
                    >
                        X
                    </Button>

                </ButtonGroup>
                <DialogTitle id="scroll-dialog-title">ANULACION</DialogTitle>
                <DialogContent>
                    {messageAlert && this.modalSubmitValid(this.props)}
                    {openModalCharged && this.modalEsperaCharged(this.props)}
                    <DialogContentText
                        id="scroll-dialog-description"
                    >
                        <React.Fragment>
                            <CustomForm
                                submitable={false}
                                items={ANULACION_FORM}
                                formName={ANULACION_FORM_NAME}
                                className="people-form"
                                dynamicOptions={dynamicOptions}
                            />
                        </React.Fragment>
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <ButtonGroup className="anulation-devolution" color="primary" aria-label="primary button group">
                        <Button
                            onClick={() => closeAnulacionTable({ destroy: true })}
                        >
                            Cancelar
                        </Button>

                        <Button
                            onClick={() => actionsButtonsAprove({ type: "ANULAR", from: "table", data: openAnulationTable })}
                        >
                            Anular
                        </Button>
                    </ButtonGroup>

                </DialogActions>
            </Dialog>
        );

    }
    modalConfirmacion() {
        const { messageConfirmation, closeModales, savedActionsValidation } = this.props;
        return (
            <div >
                <Dialog
                    className="modal-confirmation"
                    open
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Información</DialogTitle>
                    <DialogContent>

                        <DialogContentText
                            id="scroll-dialog-description"
                        //tabIndex={-1}
                        >
                            {messageConfirmation.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ButtonGroup color="primary" aria-label="primary button group">
                            <Button
                                onClick={() => closeModales()}
                            >
                                Cancelar
                            </Button>


                            <Button onClick={() => savedActionsValidation({ messageConfirmation })}>
                                Aceptar
                            </Button>

                        </ButtonGroup>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    modalSubmitValid() {
        const { messageAlert, returnToHome, closeModales } = this.props;
        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="messageSucess">
                    <React.Fragment>
                        {messageAlert.error ? <Alert className="alert danger">
                            <p>{messageAlert.message}</p>
                        </Alert>
                            :
                            <Alert className="alertsucessBorrador" >
                                <p>{messageAlert.message}</p>
                            </Alert>
                        }
                    </React.Fragment>


                    <div>
                        <Button
                            className='buttonFinalizar'
                            color="default"
                            onClick={messageAlert.home ? () => returnToHome() : () => closeModales()}
                        >
                            Aceptar
                        </Button>
                    </div>


                </div>
            </Modal>
        );
    }

    modalEsperaCharged() {
        return (
            <Modal disablePortal disableEnforceFocus disableAutoFocus open aria-hidden="true">
                <div className="modalEsperaCharged">
                    <CircularProgress style={{ width: "70px", height: "70px" }} />
                </div>
            </Modal>
        );
    };
    render() {
        const {
            sessionValidated,
            validarTipoUser,
            dataRegVivienda,
            consultaPaginada,
            paginarDatosRegEmpresas,
            searchFormFromTable,
            showSnackBar,
            errorTryCatch,
            openMondalTryCatch,
            seeFilesUnified,
            devolverPostulationTable,
            anularPostulationTable,
            openFormBandeja,
            returnToHome,
            openAnexosTable,
            openAnulationTable,
            openDevolutionTable,
            messageConfirmation,
            bandejaAdmin,
        } = this.props;

        const authUser = getFromSession('authUserColaborador');

        if (authUser && authUser.email && !sessionValidated) {
            if (!sessionValidated)
                validarTipoUser(authUser);
        }

        return (
            <React.Fragment>

                {openMondalTryCatch ? this.modalTryCatch(errorTryCatch) : ""}
                {showSnackBar ? this.showSnackBar(showSnackBar, "Sucess") : ""}
                {openAnexosTable && this.openModalAnexosTable(this.props)}
                {openDevolutionTable && this.openModalDevolucion(this.props)}
                {openAnulationTable && this.openModalAnulacion(this.props)}


                {messageConfirmation && messageConfirmation.from === "table" && this.modalConfirmacion(this.props)}

                <h1 className="Encabezado-ra">{"Validación Comercial vivienda"}</h1>
                <div className="Content-form">
                    <div className="Content-nav">

                        <LoginButton
                            urlRedirect={`${window.location.origin}/bandeja`}
                            callbackPath="/bandeja"
                        />
                        <div className="button-nav-bandeja">
                            <ButtonGroup color="primary" aria-label="primary button group">
                                <Button onClick={() => returnToHome()}>
                                    <HomeIcon />
                                </Button>

                            </ButtonGroup>
                        </div>
                        {/* <div className="button-nav-bandeja">
                            <ButtonGroup color="primary" aria-label="primary button group">
                                <Button onClick={() => console.log('open informes')} startIcon={<EqualizerIcon />}>
                                    {/* <Button onClick={() => openComponentInformes()} startIcon={<EqualizerIcon />}></Button> */}
                        {/*       Informes
                                </Button>

                            </ButtonGroup>
                        </div> */}

                    </div>
                    <div className="Content-body">

                        <React.Fragment>
                            {!openFormBandeja ?
                                <div className="p-mt-3">
                                    {dataRegVivienda && dataRegVivienda.length > 0 ?
                                        <DataTableWithPaginator
                                            data={dataRegVivienda}
                                            columns={HEADER_TABLE_PAGINATOR_EMPRESAS} paginarData={paginarDatosRegEmpresas}
                                            //seleccionRangoBusqueda={this.state.seleccionRango}
                                            searchFormFromTable={searchFormFromTable}
                                            tituloTabla={TITLE_TABLE_PAGINATOR_EMPRESAS} bandejaAdmin={bandejaAdmin}
                                            modeSelection={"button"} seeFilesUnified={seeFilesUnified} devolverPostulationTable={devolverPostulationTable}
                                            dataPaginada={dataRegVivienda} consultaPaginada={consultaPaginada}
                                            nombreConsulta={"convsPresupuesto"} dataToDownloadCsv={dataRegVivienda}
                                            totalConvsFiltradas={dataRegVivienda.length} anularPostulationTable={anularPostulationTable}
                                        //convsToDownloadCsv={convsToDownloadCsv} descargarCSV={exportDataToXlsx}
                                        />
                                        :
                                        <div className="datosPorValidar">
                                            <p>{DATA_POR_VALIDAR}</p>
                                            <img src="./Recurso-1.png"></img>
                                        </div>
                                    }
                                </div>
                                : <ComponetBandeja />
                            }

                        </React.Fragment>




                        {/* <Dashboard handleChange={handleChange} tab={cuerrentTab} logoutAction={logoutAction} /> */}

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        showSnackBar: getSnackBar(state),
        errorTryCatch: getErrorTryCatch(state),
        openMondalTryCatch: getOpenMondalTryCatch(state),
        sessionValidated: getSessionValidated(state),
        dataRegVivienda: getDataRegVivienda(state),
        consultaPaginada: getConsultaPaginada(state),
        openFormBandeja: getOpenFormBandeja(state),
        openAnexosTable: getOpenAnexosTable(state),
        dataAnexostable: getDataAnexostable(state),
        dynamicOptions: getDynamicOptions(state),
        openAnulationTable: getOpenAnulationTable(state),
        openDevolutionTable: getOpenDevolutionTable(state),
        messageAlert: getMessageAlert(state),
        messageConfirmation: getMessageConfirmation(state),
        openModalCharged: getOpenModalCharged(state),
        bandejaAdmin: getBandejaAdmin(state),
    };
};

const mapDispachToProps = dispatch => {
    return {
        validarTipoUser: (value) => dispatch({ type: VALIDATE_USER_TYPE, value }),
        paginarDatosRegEmpresas: (value) => dispatch({ type: PAGINAR_DATA_REG_EMPRESAS, value }),
        searchFormFromTable: (value) => dispatch({ type: SEARCH_FORM_FROM_TABLE, value }),
        seeFilesUnified: (value) => dispatch({ type: OPEN_SEE_FILES_UNIFIED, value }),
        devolverPostulationTable: (value) => dispatch({ type: DEVOLVER_POSTULATION_FROM_TABLE, value }),
        anularPostulationTable: (value) => dispatch({ type: ANULAR_POSTULATION_FROM_TABLE, value }),
        closeModalTryCatch: (value) => dispatch({ type: GESTIONA_MODAL_TRY_CATCH, value }),
        returnToHome: (value) => dispatch({ type: RETURN_HOME_BANDEJA, value }),
        handleSeeAnexo: (value) => dispatch({ type: VISUALIZE_ANEXOS_FROM_TABLE, value }),
        closeDevolucionTable: (value) => dispatch({ type: OPEN_MODAL_DEVOLUTION_TABLE, value }),
        closeAnulacionTable: (value) => dispatch({ type: OPEN_MODAL_ANULATION_TABLE, value }),
        actionsButtonsAprove: (value) => dispatch({ type: ACTIONS_OF_APROVE, value }),
        closeModales: (value) => dispatch({ type: CLOSE_MODALES, value }),
        savedActionsValidation: (value) => dispatch({ type: SAVED_ACTIONS_VALIDATION, value }),
        loadCatalog: (value) => dispatch({ type: LOAD_CATALOG_BANDEJA, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(BandejaComponent);
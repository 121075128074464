import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { CSVReader } from "react-papaparse";
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import CssBaseline from '@material-ui/core/CssBaseline';
import { decode, emptyJson, emptyObject } from "../../controller/utils";

import { PDFViewer, pdf } from '@react-pdf/renderer';
import MyDocument from '../exportpdf/mydocument';
import { Button, ButtonGroup } from "@material-ui/core";

import { withAuth0 } from '@auth0/auth0-react';

import {
  getFormValuesStep1,
  getFormValuesStep2,
  getFormValuesStep3,
  getFormValuesStep4,
  getFormValuesStep5,
  getFormValuesStep6,
  fullFormSelector,
  getActiveStep,
  getSnackBar,
  getIdFormulario,
  getOpenModal,
  getDynamicOptions,
  getFormInitialized,
  getFormFiles,
  getCharged,
  getDynamicDisable,
  getHiddenDisable,
  getChargedValidator,
  getActivateRadio,
  getHiddenClose,
  getOptionsTable,
  getFormFirmas,
  getReloadPage,
  getContactoAdd,
  getAuthError,
  getGeneratePdf,
  getAnexosStorage,
  getInitValidate,
  getFormValid,
  getReloadPdf,
  getMessage,
  getEnabledDidabledArray,
  getRebootFiles,
  getStep3Completo,
  getModalChargedEspera,
  getFirebaseUser,
  getEnabledAnexos,
  getActionTableAnexos,
  getUpdateUploadFile,
  getOpenFrameVideo,
  getOpenTabFrameVideo,
  getListVideoFrame,
  getFirmaLabel,
  getModalCloseForm,
  getMessageAlert,
  getFormUpdateActive,
  getInitDisableBandeja,
  getFinaliceUpdate,
  getOpenModalInstruccion,
  getOpenConfirmaInstruccion,
  getItemSaved,
  getFormBandeja,
} from "./selectors";

import {
  UPDATE_FIELD_ATTRIBUTE,
  getSubmitButtonss,
  getSubmitButtons,
  SUBMIT_FORM,
  FORM_NAME_STEP1,
  FORM_NAME_STEP2,
  FORM_NAME_STEP3,
  FORM_NAME_STEP4,
  FORM_NAME_STEP5,
  FORM_NAME_STEP6,
  INCREASE_STEP,
  SALVAR_STEP,
  DECREASE_STEP,
  HANDLE_STEP,
  INCREASE_INTEGRANTES,
  DEREASE_INTEGRANTES,
  getConformacionHogar,
  STEPS_CONTENT,
  getRecursosEconomicos,
  getModalButtons,
  OPEN_FINAL_MODAL,
  SUBMIT_FINAL_FORM,
  LOAD_CATALOG,
  CREATE_CATALOG,
  INIT_ALL_FORMS,
  UPLOAD_FILE,
  REMOVE_FILE,
  getInitButtons,
  adjuntosAnexosForm,
  ARCHIVOS_ADJUNTOS,
  JURAMENTO_ACEP,
  SAVE_FORM_FILES,
  SUMAR_DATA,
  INIT_FORMS,
  getStep1Form,
  getStep3Form,
  NEW_RECURSO_ADD,
  DELETE_RECURSO_TABLE,
  ADD_INTHOGAR,
  REMOVE_FIRMA,
  UPLOAD_FIRMA,
  SAVE_FIRMA,
  CLOSE_MODAL_F,
  RENDER_PDF,
  DELETE_ANEXO,
  REQUIRED_INFORMACION_LABORAL,
  REQUIRED_INFORMACION_BASICA,
  REQUIRED_DATOS_POSTULANTE,
  REQUIRED_CONDICION_HOGAR,
  REQUIRED_INTHOGAR_POSTULANTE,
  REQUIRED_INFORMACION_POSTULACION,
  REQUIRED_MODALIDAD_VIVIENDA,
  REQUIRED_VALOR_CONSTRUCCION,
  REQUIRED_AHORRO_PREVIO,
  REQUIRED_RECURSO_COMPLEMENTARIOS,
  REQUIRED_FINANCIACION_TOTAL,
  CLOSE_MODAL_VALID,
  RELOAD_PDF,
  REQUIRED_ENTIDAD_CREDITO,
  DELETE_FIRMA,
  LOGOUT_SESION,
  TEXT_MODAL_VALIDADOR,
  getStep3FormV2,
  GET_FIREBASE_USER_SESSION,
  ACTIONS_UPLOAD_ANEXOS_RESORSES,
  ACTIONS_FROM_FRAME_VIDEO,
  CLOSE_MODAL_MESSAGE_ALERT,
  MANAGE_FILE_TYPE_VALID,
  UPDATE_FORMULARIO_TYPE,
  adjuntosAnexosFormActualizacion,
  EDIT_RESOURSE_UPDATE_FORM,
  getSubmitButtonsUpdateForm,
  INSTRUCTION_ACTUALIZACION_FORM,
  MSS_CONFIRMA_INSTRUCCION,
  OPEN_MODAL_INSTRUCCION_FORM,
  GESTIONA_MODAL_INSTRUCCION,
  CLEAN_SECTION_RECURSOS,
  ACTIVATE_FORM_BANDEJA,
  getConformacionHogarActualizacion,
  SAVE_USER_INFO_BANDEJA,
} from "./constants";

import CustomForm from "../../components/materialform/customform";
import MuiAlert from "@material-ui/lab/Alert";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import LoginButton from "../../components/authlogin/authlogin";
import { getFromSession, saveInSession } from "../../controller/session";
import { isUndefined } from "lodash-es";
import { base64Add } from "./actions";

class ComponentLargeForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      colaborador: false,
    }
    // this.searchCountry = this.searchCountry.bind(this);
  }

  componentWillMount() {
    const { updateTypeForm, params, activateFormBandeja } = this.props;

    const paramUrl = decode(window.location.search.slice(1)).split('&').reduce((acc, s) => {
      const [k, v] = s.split('=')
      return Object.assign(acc, { [k]: v })
    }, {})

    const paramUrlSession = getFromSession('actualizacionForm');

    if (params && params.idFormulario)
      activateFormBandeja(true);

    if (paramUrlSession && paramUrlSession.type && paramUrlSession.type.includes("actualizacion")) {
      sessionStorage.clear();
      saveInSession('actualizacionForm', paramUrlSession);
      updateTypeForm(true);
    }

    if (paramUrl) {
      sessionStorage.clear();
      saveInSession('actualizacionForm', paramUrlSession);
      if (paramUrl.postulacion) {
        const dataCol = JSON.parse(paramUrl.data)
        this.setState({ colaborador: dataCol })
      }

      if (paramUrl.type && paramUrl.type.includes("actualizacion")) {
        saveInSession('actualizacionForm', paramUrl);
      }

    } else {
      sessionStorage.clear();
      saveInSession('actualizacionForm', paramUrlSession);
    }

  }

  componentDidMount() {
    const { loadCatalog, updateTypeForm, params } = this.props;
    // updateTypeForm(true);
    loadCatalog({ bandeja: params.idFormulario ? true : false, idFormulario: params.idFormulario });
    const paramUrlSession = getFromSession('actualizacionForm');
    console.log(paramUrlSession);
  }


  componentDidUpdate(prevProps) {

    const { activeStep, params, dynamicOptions, saveUserInfoBandeja, itemsForm, handleStep } = this.props;

    if (params && params.idFormulario) {
      if (params.stepForm >= 0 && params.stepForm !== prevProps.activeStep && params.stepForm !== activeStep) {
        handleStep(params.stepForm)
      }

      // if (revisePantalla)
      //   initializeRevisaPantalla(itemsForm);

      // if (stepLimite && params.stepFinal === false)

      if (dynamicOptions && dynamicOptions.city && itemsForm && !params.formCharged) {

        params.setItemsForm(itemsForm.item)
        params.setAnexos(itemsForm.item.refFiles.refStorage);
        params.setCatalog(dynamicOptions)
        params.setFormCharged(true);
        params.setStepFinal(5);
        saveUserInfoBandeja(params.userInfoBandeja)
      }
    }

    if (activeStep !== prevProps.activeStep) {
      window.scrollTo(0, 0);
    }



  }


  handleOnDrop = (data) => {
  };

  handleOnError = (err, file, inputElem, reason) => {
  };

  handleOnRemoveFile = (data) => { }

  handleOnOpenFrameVideo = (data) => {
    const { actionFrameVideo, activeStep, listVideoFrame } = this.props;
    return (
      <React.Fragment>
        <div className="video-container">
          <div className="button-closed">
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={"classes.button"}
              onClick={() => actionFrameVideo("open")}
            >

              <HighlightOffIcon />
            </Button>
          </div>
          <div className="video-frame">
            <iframe width="560" height="315" src={`${listVideoFrame[`_${activeStep}`]}`}//"https://www.youtube.com/embed/O-u-cLDJApo?si=DYF0XcvdgNBGDKq0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen>

            </iframe>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleOnOpenFileVideo = (data) => {
    const { actionFrameVideo } = this.props;
    return (
      <React.Fragment>
        <div className="button-openFrame">
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={"classes.button"}
            startIcon={<SlowMotionVideoIcon />}
            onClick={() => actionFrameVideo("open")}
          >
            Como diligenciar mi información
          </Button>
        </div>

      </React.Fragment>
    );
  }

  validateStep1 = (values) => {
    let errors = {
      informacionbasica: { subsidio: "" },
      datospostulante: {},
      informacionlaboral: {},
    };
    var ban = false

    if (values) {
      const requiredInformacionBasica = REQUIRED_INFORMACION_BASICA;
      const requiredDatosPostulante = REQUIRED_DATOS_POSTULANTE;
      const requiredInformacionLaboral = REQUIRED_INFORMACION_LABORAL;

      if (values.informacionlaboral) {
        errors.informacionlaboral = {}
        if (!values.informacionlaboral.laborandoSelect || isUndefined(values.informacionlaboral.laborandoSelect)) {
          errors.informacionlaboral["laborandoSelect"] = "Campo obligatorio";
        } else {
          if (values.informacionlaboral.laborandoSelect === "otraEmpresa") {
            requiredInformacionLaboral.forEach((field) => {
              if (!values.informacionlaboral[field]) {
                errors.informacionlaboral[field] = "Campo obligatorio";
              }
            });
          } else {
            errors.informacionlaboral = {};
          }
        }



      }

      if (values.informacionbasica) {
        requiredInformacionBasica.forEach((field) => {
          if (isUndefined(values.informacionbasica[field]) || !values.informacionbasica[field]) {
            errors.informacionbasica[field] = "Campo obligatorio";
          }
        });
        if (values?.informacionbasica?.subsidio === "1") {
          if (!values.informacionbasica["entidadSubsidio"]) {
            errors.informacionbasica["entidadSubsidio"] = "Campo obligatorio";
          }
        }

      }
      if (values.datospostulante) {
        requiredDatosPostulante.forEach((field) => {
          if (isUndefined(values.datospostulante[field]) || !values.datospostulante[field]) {
            errors.datospostulante[field] = "Campo obligatorio";
          }
        });

      }

      if (values.informacionbasica && values.informacionbasica.entidadSubsidio && values.informacionbasica.entidadSubsidio.length > 250) {
        errors.informacionbasica["entidadSubsidio"] = "Longitud maxima de caracteres 250";
      }

      if (values.informacionlaboral && values.informacionlaboral.companyPhoneJob && values.informacionlaboral.companyPhoneJob.length > 250) {
        errors.informacionlaboral["companyPhoneJob"] = "Longitud maxima de caracteres 250";
      }

      if (values.informacionlaboral && values.informacionlaboral.companyJobAddress && values.informacionlaboral.companyJobAddress.length > 250) {
        errors.informacionlaboral["companyJobAddress"] = "Longitud maxima de caracteres 250";
      }

      if (values.informacionlaboral && values.informacionlaboral.companyJobName && values.informacionlaboral.companyJobName.length > 250) {
        errors.informacionlaboral["companyJobName"] = "Longitud maxima de caracteres 250";
      }


      if (
        values.datospostulante &&
        values.datospostulante.companyEmail &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          values.datospostulante.companyEmail
        )
      ) {
        errors.datospostulante.companyEmail = "Formato de correo electrónico invalido";
      }

      // if (values.informacionlaboral && values.informacionlaboral.companyPhoneJob && values.informacionlaboral.companyPhoneJob.length !== 7) {
      //   errors.informacionlaboral.companyPhoneJob = "Número de teléfono invalido";
      // }

      if (values.datospostulante && values.datospostulante.companyPhone) {

        if (values.datospostulante.companyPhone.length > 11) {
          const aux = values.datospostulante.companyPhone.split("-")
          aux.forEach(element => {
            if (!(element.length === 7 || element.length === 10)) {
              errors.datospostulante.companyPhone = "Número de teléfono " + element + " invalido";
              return;
            }
          });
        } else {
          if (!(values.datospostulante.companyPhone.length === 7 || values.datospostulante.companyPhone.length === 10)) {
            errors.datospostulante.companyPhone = "Número de teléfono invalido";
          }
        }

      }


    }
    return errors;
  };

  validateStep2 = (values) => {
    let errors = {
      condicionHogar: {},
      inthogarpostulantes: [],

    };
    if (values) {
      const requiredCondicionHogar = REQUIRED_CONDICION_HOGAR;
      const requiredIntegrantesHogarPostulante = REQUIRED_INTHOGAR_POSTULANTE;

      if (values.condicionHogar) {
        requiredCondicionHogar.forEach((field) => {
          if (!values.condicionHogar[field]) {
            errors.condicionHogar[field] = "Campo obligatorio";
          }
        });
      }

      if (values.inthogarpostulantes) {

        var c = 0;
        requiredIntegrantesHogarPostulante.forEach((field) => {
          values.inthogarpostulantes.forEach(element => {
            if (!element[field]) {
              errors.inthogarpostulantes[field] = "Campo obligatorio";

            }
            c = c + 1;
          });
        });
      }



      let date = new Date();
      let fecha =
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

      if (values.condicionhogar && values.condicionhogar.birthdate) {
        const fechaSis = Date.parse(values.condicionhogar.birthdate);
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.condicionhogar.birthdate = "Rango de fecha invalido";
        }
      }
    }
    return errors;
  };

  validateStep3 = (values) => {
    let errors = {
      informacionpostulacion: {},
      modalidadvivienda: {},
      valorconstruccion: {},
    };
    if (values) {
      const requiredInformacionPostulacion = REQUIRED_INFORMACION_POSTULACION;
      const requiredModalidadVivienda = REQUIRED_MODALIDAD_VIVIENDA;
      const requiredValorConstruccion = REQUIRED_VALOR_CONSTRUCCION;

      if (values.valorconstruccion) {
        requiredValorConstruccion.forEach((field) => {
          if (!values.valorconstruccion[field]) {
            errors.valorconstruccion[field] = "Campo obligatorio";
          }
        });
      }

      if (values.informacionpostulacion) {
        requiredInformacionPostulacion.forEach((field) => {
          if (!values.informacionpostulacion[field]) {
            errors.informacionpostulacion[field] = "Campo obligatorio";
          }
        });
      }

      if (values.modalidadvivienda) {
        requiredModalidadVivienda.forEach((field) => {
          if (!values.modalidadvivienda[field]) {
            errors.modalidadvivienda[field] = "Campo obligatorio";
          }
        });
      }

      if (values.informacionpostulacion && values.informacionpostulacion.projectname && values.informacionpostulacion.projectname.length > 250) {
        errors.informacionpostulacion["projectname"] = "Longitud maxima de caracteres 250";
      }

      if (values.informacionpostulacion && values.informacionpostulacion.ofername && values.informacionpostulacion.ofername.length > 250) {
        errors.informacionpostulacion["ofername"] = "Longitud maxima de caracteres 250";
      }

      let date = new Date();
      let fecha =
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

      if (values.condicionvivienda && values.condicionvivienda.escriturafecha) {
        const fechaSis = Date.parse(values.condicionvivienda.escriturafecha);
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.condicionvivienda.escriturafecha = "Invalid date range";
        }
      }

      if (
        values.condicionvivienda &&
        values.condicionvivienda.registertestdate
      ) {
        const fechaSis = Date.parse(values.condicionvivienda.registertestdate);
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.condicionvivienda.registertestdate = "Invalid date range";
        }
      }
    }
    return errors;
  };

  validateStep4 = (values) => {
    let errors = {
      ahorroprevio: {},
      recursoscomplementarios: {},
      financiaciontotal: {},
      cuentasCredito: {},
    };

    if (values) {
      const requiredAhorroPrevio = REQUIRED_AHORRO_PREVIO;
      const requiredRecursoComplementarios = REQUIRED_RECURSO_COMPLEMENTARIOS;
      const requiredFinanciacionTotal = REQUIRED_FINANCIACION_TOTAL;
      const requiredEntidadCredito = REQUIRED_ENTIDAD_CREDITO;
      if (values.ahorroprevio && !emptyJson(values.ahorroprevio)) {
        if (values.ahorroprevio?.tiporecursoA) {
          if (values.ahorroprevio.tiporecursoA === "9") {
            if (!values.ahorroprevio["otrorecursoA"]) {
              errors.ahorroprevio["otrorecursoA"] = "Campo obligatorio";
            }
          }
        }
        requiredAhorroPrevio.forEach((field) => {
          if (!values.ahorroprevio[field]) {
            errors.ahorroprevio[field] = "Campo obligatorio";
          }
        });
      }

      if (values.recursoscomplementarios && !emptyJson(values.recursoscomplementarios)) {
        if (values?.recursoscomplementarios?.tiporecursoR) {
          if (values.recursoscomplementarios.tiporecursoR === "5") {
            if (!values.recursoscomplementarios["otrorecursoR"]) {
              errors.recursoscomplementarios["otrorecursoR"] = "Campo obligatorio";
            }
          }
        }
        requiredRecursoComplementarios.forEach((field) => {
          if (!values.recursoscomplementarios[field]) {
            errors.recursoscomplementarios[field] = "Campo obligatorio";
          }
        });
      }

      if (values.financiaciontotal && !emptyJson(values.financiaciontotal)) {
        requiredFinanciacionTotal.forEach((field) => {
          if (!values.financiaciontotal[field]) {
            errors.financiaciontotal[field] = "Campo obligatorio";
          }
        });
      }
      if (values.cuentasCredito && !emptyJson(values.cuentasCredito)) {
        requiredEntidadCredito.forEach((field) => {
          if (!values.cuentasCredito[field]) {
            errors.cuentasCredito[field] = "Campo obligatorio";
          }
        });
      }



      let date = new Date();
      let fecha =
        date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

      if (values.ahorroprevio && values.ahorroprevio.opendate) {
        const fechaSis = Date.parse(values.ahorroprevio.opendate);
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.ahorroprevio.opendate = "Invalid date range";
        }
      }

      if (
        values.recursocomplementariosection &&
        values.recursocomplementariosection.opendate
      ) {
        const fechaSis = Date.parse(
          values.recursocomplementariosection.opendate
        );
        if (
          fechaSis < Date.parse("1900-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.recursocomplementariosection.opendate = "Invalid date range";
        }
      }
    }
    return errors;
  };
  validateStep6 = (values) => {
    var errors = {
      juramentoPartes: {},
    };

    if (values) {

      const juramentopartes = [
        "juramentoCheck",
      ];
      if (values?.juramentoPartes) {
        if (!values.juramentoPartes["juramentoCheck"] || values.juramentoPartes["juramentoCheck"] === false) {
          errors.juramentoPartes.juramentoCheck =
            "Tiene que aceptar el Juramento";
        }
      }

    }

    return errors;
  };

  showSnackBar(open, message) {
    return (
      <Snackbar open={open} autoHideDuration={1} className="ocultar">
        <Alert severity="success">{message}</Alert>
      </Snackbar>
    );
  }

  modalSubmitConfirmation(props) {
    const { openModal, closeModal, submitFinalForm } = props;

    return (

      <Dialog
        className="submit-final-form"
        open
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"

      >
        <DialogTitle id="scroll-dialog-title">Finalizar formulario</DialogTitle>
        <DialogContent>

          <CustomForm
            submitable={false}
            items={[]}
            formName={"instructionForm"}
            handleSubmit={false}
            className="modal-submit-basica-form"
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup color="primary" aria-label="primary button group">
            <Button
              onClick={() => closeModal()}
            >
              Cancelar
            </Button>

            <Button
              onClick={() => submitFinalForm()}
            >
              Finalizar
            </Button>

          </ButtonGroup>

        </DialogActions>
      </Dialog>


    );
  }

  modalSubmitInstruccionUpdate(props) {
    const { openConfirmaInstruccion, closeModalInstruccion, gestionaConfirmaInstruccion } = props;

    return (
      <Dialog
        className="modal-Instruccion"
        open
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">INSTRUCCIÓN ACTUALIZACION POSTULACIÓN</DialogTitle>
        <DialogContent>
          {openConfirmaInstruccion === false ? ""
            :
            <React.Fragment>
              <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                aria-hidden="true"
                open
              >
                <div className="messageSucess">
                  <React.Fragment>
                    <Alert className="alertsucessBorrador" >
                      <p>{MSS_CONFIRMA_INSTRUCCION}</p>
                    </Alert>

                  </React.Fragment>


                  <div>
                    <ButtonGroup color="primary" aria-label="primary button group">

                      <Button
                        className="btn-cancelar"
                        onClick={() => gestionaConfirmaInstruccion(false)}
                      >
                        Cancelar
                      </Button>

                      <Button
                        className="btn-aceptar"
                        onClick={() => closeModalInstruccion()}
                      >
                        Aceptar
                      </Button>
                    </ButtonGroup>
                  </div>


                </div>
              </Modal>

            </React.Fragment>
          }
          <DialogContentText
            id="scroll-dialog-description"
          >
            <CustomForm
              submitable={false}
              items={INSTRUCTION_ACTUALIZACION_FORM}
              formName={"instructionForm"}
              handleSubmit={false}
              className="people-form"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup color="primary" aria-label="primary button group">
            <Button
              onClick={() => gestionaConfirmaInstruccion(true)}
            >
              Aceptar
            </Button>

          </ButtonGroup>

        </DialogActions>
      </Dialog>
    );


  }

  modalSubmitEspera(props) {
    const { reloadPage,
      contactoAdd,
      error, closeModal, initReload } = props;

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-hidden="true"
        open
      >
        <div className="messageSucessViv">
          <React.Fragment>
            {error ? (
              <Alert className="alert danger" >
                {contactoAdd}
              </Alert>
            ) : (
              <Alert className="alert success" >
                {contactoAdd}
              </Alert>
            )}
          </React.Fragment>
          {reloadPage === false ?
            <div>
              <CircularProgress />
            </div>
            :
            <div>
              {error ?
                <Button
                  className='buttonFinalizar'
                  variant="text"
                  color="secondary"
                  onClick={() => closeModal()}
                >
                  Volver
                </Button> :
                <Button
                  className='buttonFinalizar'
                  variant="text"
                  color="default"
                  onClick={() => initReload()}
                >
                  Finalizar
                </Button>
              }

            </div>
          }
        </div>
      </Modal>
    );
  }
  modalSubmitValid(props) {
    const { formValid, closeModalValid } = props;

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-hidden="true"
        open
      >
        <div className="messageSucess">
          {formValid && formValid.error ?
            <React.Fragment>
              <Alert className="alertsucessBorrador" >
                {formValid.mes}
              </Alert>
              {formValid && formValid.errorMo ?
                <div>

                  <CircularProgress />
                </div>
                : ''}
            </React.Fragment>
            :
            <React.Fragment>
              <Alert className="alert danger" >
                {formValid.mes ? formValid.mes : formValid}
              </Alert>
            </React.Fragment>
          }

          {formValid && formValid.errorMo ? '' :
            <div>
              <Button
                className='buttonFinalizar'
                variant="text"
                color="default"
                onClick={() => closeModalValid()}
              >
                Continuar
              </Button>

            </div>
          }

        </div>
      </Modal>
    );
  }

  openModalCloseForm(props) {
    const { modalCloseForm, closeModalValid } = props;

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
        aria-hidden="true"
      >
        <div className="messageSucess">
          <React.Fragment>
            <Alert className="alert danger" >
              {modalCloseForm.message}
            </Alert>
          </React.Fragment>
          <div>
            <Button
              className='buttonFinalizar'
              variant="text"
              color="default"
              onClick={() => window.location.replace("https://www.comfenalcovalle.com.co/")}
            >
              Aceptar
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  modalMessageAlert(props) {
    const { messageAlert, closeModalMessageAlert } = props;

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
        aria-hidden="true"
      >
        <div className="messageSucess">
          <React.Fragment>
            <Alert className="alert danger" >
              {messageAlert.message}
            </Alert>
          </React.Fragment>
          <div>
            <Button
              className='buttonFinalizar'
              variant="text"
              color="default"
              onClick={() => closeModalMessageAlert()}
            >
              Aceptar
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  modalEsperaCharged() {

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
        aria-hidden="true"
      >
        <div className="modalEsperaCharged">
          <CircularProgress style={{ width: '70px', height: '70px' }} />
        </div>
      </Modal>
    );
  }


  getStepContent(props) {
    const {
      activeStep,
      increaseStep,
      decreaseStep,
      openSubmitModal,
      formFiles,
      formFirmas,
      dynamicOptions,
      optionsInthogar,
      dynamicDisable,
      hiddenDisable,
      hiddenClose,
      anexosStorage,
      salvarStep,
      handleUpload,
      handleSuma,
      handleRemove,
      handleRemoveF,
      handleUploadF,
      charged,
      activateRadio,
      enableDisableArray,
      handleAdd,
      optionsTable,
      deleteRecurso,
      editRecurso,
      deleteAnexo,
      deleteFirma,
      addInthogar,
      initValid,
      rebootFiles,
      step3completo,
      enabledAnexos,
      uploadAnexosResources,
      actionTableAnexos,
      updateUploadFile,
      firmaLabel,
      manageValidFileType,
      formUpdateActive,
      initDisableBandeja,
      cleanFormRecursos,
      formBandeja
    } = props;

    switch (activeStep) {
      case 0:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={JURAMENTO_ACEP}
              formName={FORM_NAME_STEP6}
              validations={this.validateStep6}
              handleSubmit={() => increaseStep()}
              buttons={getInitButtons(() => increaseStep)}
              charged={charged}
              anexosStorage={anexosStorage}
              dynamicOptions={dynamicOptions}
              enableDisableArray={enableDisableArray}
              dynamicDisable={dynamicDisable}
              initDisableBandeja={initDisableBandeja}
              formBandeja={formBandeja}
              className="people-form"
            />
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={getStep1Form()}
              activateRadio={activateRadio}
              validations={this.validateStep1}
              formName={FORM_NAME_STEP1}
              handleSubmit={() => increaseStep()}
              initValid={initValid}
              buttons={getSubmitButtonss(
                () => decreaseStep(),
                () => increaseStep()
              )}
              charged={charged}
              dynamicOptions={dynamicOptions}
              dynamicDisable={dynamicDisable}
              enableDisableArray={enableDisableArray}
              className="informacion-basica-form"
              hiddenDisable={hiddenDisable}
              optionsTable={optionsTable}
              initDisableBandeja={initDisableBandeja}
              formBandeja={formBandeja}
            />
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={formUpdateActive ? getConformacionHogar(handleRemoveF, handleUploadF,
                handleSuma) : getConformacionHogar(handleRemoveF, handleUploadF,
                  handleSuma)}
              hiddenDisable={hiddenDisable}
              addInthogar={addInthogar}
              handleSuma={handleSuma}
              enableDisableArray={enableDisableArray}
              deleteFirma={deleteFirma}
              initValid={initValid}
              formName={FORM_NAME_STEP2}
              validations={this.validateStep2}
              handleSubmit={() => increaseStep(formFirmas)}
              buttons={getSubmitButtonss(
                () => decreaseStep(),
                () => decreaseStep()
              )}
              charged={charged}
              optionsInthogar={optionsInthogar}
              dynamicOptions={dynamicOptions}
              dynamicDisable={dynamicDisable}
              firmaLabel={firmaLabel}
              initDisableBandeja={initDisableBandeja}
              formBandeja={formBandeja}
              className="conformacion-hogar-form"

            />
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={step3completo ? getStep3Form(activateRadio) : getStep3FormV2(activateRadio)}
              initValid={initValid}
              formName={FORM_NAME_STEP3}
              validations={this.validateStep3}
              handleSubmit={() => increaseStep()}
              buttons={getSubmitButtonss(
                () => decreaseStep(),
                () => decreaseStep()
              )}
              charged={charged}
              dynamicOptions={dynamicOptions}
              dynamicDisable={dynamicDisable}
              hiddenDisable={hiddenDisable}
              initDisableBandeja={initDisableBandeja}
              formBandeja={formBandeja}
              className="postulacion-form"
            />
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={getRecursosEconomicos(handleAdd, uploadAnexosResources, cleanFormRecursos, manageValidFileType)}
              handleSuma={handleSuma}
              hiddenClose={hiddenClose}
              deleteRecurso={deleteRecurso}
              editRecurso={editRecurso}
              hiddenDisable={hiddenDisable}
              formName={FORM_NAME_STEP4}
              validations={this.validateStep4}
              handleSubmit={() => increaseStep()}
              buttons={getSubmitButtonss(
                () => decreaseStep(),
                () => decreaseStep()
              )}
              rebootFiles={rebootFiles}
              charged={charged}
              dynamicOptions={dynamicOptions}
              initValid={initValid}
              dynamicDisable={dynamicDisable}
              enabledAnexos={enabledAnexos}
              actionTableAnexosResources={actionTableAnexos}
              updateUploadFile={updateUploadFile}
              initDisableBandeja={initDisableBandeja}
              formBandeja={formBandeja}
              className="people-form"
            />
          </React.Fragment>
        );
      case 5:
        return (
          <React.Fragment>
            <CustomForm
              submitable={false}
              items={formUpdateActive || formBandeja ? adjuntosAnexosFormActualizacion(handleUpload,
                handleRemove) : adjuntosAnexosForm(handleUpload,
                  handleRemove)}
              handleUpload={handleUpload}
              handleRemove={handleRemove}
              deleteAnexo={deleteAnexo}
              formName={FORM_NAME_STEP5}
              handleSubmit={() => openSubmitModal()}
              buttons={formUpdateActive ? getSubmitButtonsUpdateForm(() => decreaseStep()) :
                getSubmitButtons(() => decreaseStep())}
              formTitle={ARCHIVOS_ADJUNTOS}
              charged={charged}
              dynamicOptions={dynamicOptions}
              dynamicDisable={dynamicDisable}
              updateUploadFile={updateUploadFile}
              initDisableBandeja={initDisableBandeja}
              formBandeja={formBandeja}
              className="people-form"
            />
          </React.Fragment>
        );


      default:
        return (
          <MuiAlert elevation={6} variant="filled" severity="info">
            Formulario en construcción
          </MuiAlert>
        );
    }
  }

  render() {
    const {
      activeStep,
      handleStep,
      showSnackBar,
      createCatalog,
      modalChargedEspera,
      loadCatalog,
      openModal,
      chargedValidator,
      generateAPdf,
      contactoAdd,
      formValid,
      reloadPdf,
      firebaseUser,
      openFrameVideo,
      modalCloseForm,
      messageAlert,
      finaliceUpdate,
      openInstruccionFom,
      params,
      formBandeja,
      formUpdateActive,
    } = this.props;

    return (
      <div className="App auth-container">

        {chargedValidator === true ?
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open
            aria-hidden="true"
            className="modalValidador"
          >

            <div className="validador_div" >
              {TEXT_MODAL_VALIDADOR}
              <CircularProgress />
            </div>


          </Modal>
          : ""}
        <React.Fragment >
          <Stepper className="stepper-form" activeStep={activeStep}>
            {STEPS_CONTENT.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>

                  <StepButton
                    onClick={formBandeja ?
                      "" : () => handleStep(index)}
                    {...labelProps}
                  >
                    {label}
                  </StepButton>

                </Step>
              );
            })}
          </Stepper>
          {messageAlert && this.modalMessageAlert(this.props)}
          {/* {openFrameVideo ? this.handleOnOpenFrameVideo() : this.handleOnOpenFileVideo()} */}
          {this.getStepContent(this.props)}
          {modalChargedEspera ? this.modalEsperaCharged() : ""}
          {showSnackBar ? this.showSnackBar(
            showSnackBar,
            "Sucess"
          ) : ""}
          {openInstruccionFom && !formBandeja && this.modalSubmitInstruccionUpdate(this.props)}
          {modalCloseForm && this.openModalCloseForm(this.props)}
          {openModal && this.modalSubmitConfirmation(this.props)}
          {contactoAdd ? this.modalSubmitEspera(this.props) : ''}
          {formValid ? this.modalSubmitValid(this.props) : ''}
          {generateAPdf.state && reloadPdf ? window.open(`exportpdf/${formUpdateActive ? "actualizacionVivienda" : "vivienda"}/` + generateAPdf.id, "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0,") : ""}

          {reloadPdf || finaliceUpdate ?
            // window.location.replace("https://pruebas.comfenalcovalle.com.co/delagentewebtest/1/1") :
            window.location.replace("https://www.comfenalcovalle.com.co/") :
            ""}

          {false && (
            <CSVReader
              onDrop={createCatalog}
              onError={this.handleOnError}
              noDrag
              addRemoveButton
              onRemoveFile={this.handleOnRemoveFile}
            >
              <span>Click to upload.</span>
            </CSVReader>
          )}
        </React.Fragment>
        {params.idFormulario ? "" :
          <LoginButton
            firebaseUser={firebaseUser}
            colaboradorUser={this.state.colaborador}
            urlRedirect={`${window.location.origin}/vivienda`}
            callbackPath="/vivienda"
          />}
        {/* {params.idFormulario ? "" :
            </LoginButton>} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formValuesStep1: getFormValuesStep1(state),
    formValuesStep2: getFormValuesStep2(state),
    formValuesStep3: getFormValuesStep3(state),
    formValuesStep4: getFormValuesStep4(state),
    formValuesStep5: getFormValuesStep5(state),
    formValuesStep6: getFormValuesStep6(state),
    formValues: fullFormSelector(state),
    activeStep: getActiveStep(state),
    showSnackBar: getSnackBar(state),
    idFormulario: getIdFormulario(state),
    openModal: getOpenModal(state),
    dynamicOptions: getDynamicOptions(state),
    optionsInthogar: getDynamicOptions(state),
    dynamicDisable: getDynamicDisable(state),
    activateRadio: getActivateRadio(state),
    optionsTable: getOptionsTable(state),
    hiddenDisable: getHiddenDisable(state),
    hiddenClose: getHiddenClose(state),
    formInitialized: getFormInitialized(state),
    formFiles: getFormFiles(state),
    generateAPdf: getGeneratePdf(state),
    formFirmas: getFormFirmas(state),
    charged: getCharged(state),
    message: getMessage(state),
    chargedValidator: getChargedValidator(state),
    reloadPage: getReloadPage(state),
    contactoAdd: getContactoAdd(state),
    error: getAuthError(state),
    anexosStorage: getAnexosStorage(state),
    initValid: getInitValidate(state),
    formValid: getFormValid(state),
    reloadPdf: getReloadPdf(state),
    enableDisableArray: getEnabledDidabledArray(state),
    rebootFiles: getRebootFiles(state),
    step3completo: getStep3Completo(state),
    modalChargedEspera: getModalChargedEspera(state),
    firebaseUser: getFirebaseUser(state),
    enabledAnexos: getEnabledAnexos(state),
    actionTableAnexos: getActionTableAnexos(state),
    updateUploadFile: getUpdateUploadFile(state),
    openFrameVideo: getOpenFrameVideo(state),
    openTabFrameVideo: getOpenTabFrameVideo(state),
    listVideoFrame: getListVideoFrame(state),
    firmaLabel: getFirmaLabel(state),
    modalCloseForm: getModalCloseForm(state),
    messageAlert: getMessageAlert(state),
    formUpdateActive: getFormUpdateActive(state),
    initDisableBandeja: getInitDisableBandeja(state),
    finaliceUpdate: getFinaliceUpdate(state),
    openInstruccionFom: getOpenModalInstruccion(state),
    openConfirmaInstruccion: getOpenConfirmaInstruccion(state),
    itemsForm: getItemSaved(state),
    formBandeja: getFormBandeja(state),

  };
};

const mapDispachToProps = (dispatch) => {
  return {
    updateAttributes: (value) =>
      dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
    increaseStep: (value) => dispatch({ type: INCREASE_STEP, value }),
    salvarStep: (value) => dispatch({ type: SALVAR_STEP, value }),
    decreaseStep: (value) => dispatch({ type: DECREASE_STEP, value }),
    handleStep: (value) => dispatch({ type: HANDLE_STEP, value }),
    logoutSesion: (value) => dispatch({ type: LOGOUT_SESION, value }),
    increaseInt: (value) => dispatch({ type: INCREASE_INTEGRANTES, value }),
    decreaseInt: (value) => dispatch({ type: DEREASE_INTEGRANTES, value }),
    updateField: (value) => dispatch({ type: "UPDATE_SINGLE_FIELD", value }),
    submitForm: (value) => dispatch({ type: SUBMIT_FORM, value }),
    closeModal: (value) => dispatch({ type: CLOSE_MODAL_F, value }),
    closeModalValid: (value) => dispatch({ type: CLOSE_MODAL_VALID, value }),
    openSubmitModal: (value) => dispatch({ type: OPEN_FINAL_MODAL, value }),
    saveFormFiles: (value) => dispatch({ type: SAVE_FORM_FILES, value }),
    saveFormFirmas: (value) => dispatch({ type: SAVE_FIRMA, value }),
    submitFinalForm: (value) => dispatch({ type: SUBMIT_FINAL_FORM, value }),
    loadCatalog: (value) => dispatch({ type: LOAD_CATALOG, value }),
    createCatalog: (value) => dispatch({ type: CREATE_CATALOG, value }),
    initForms: (value) => dispatch({ type: INIT_FORMS, value }),
    handleAdd: (value) => dispatch({ type: NEW_RECURSO_ADD, value }),
    addInthogar: (value) => dispatch({ type: ADD_INTHOGAR, value }),
    handleRemove: (value) => dispatch({ type: REMOVE_FILE, value }),
    handleUpload: (value) => dispatch({ type: UPLOAD_FILE, value }),
    handleRemoveF: (value) => dispatch({ type: REMOVE_FIRMA, value }),
    handleUploadF: (value) => dispatch({ type: UPLOAD_FIRMA, value }),
    handleSuma: (value) => dispatch({ type: SUMAR_DATA, value }),
    deleteRecurso: (value) => dispatch({ type: DELETE_RECURSO_TABLE, value }),
    editRecurso: (value) => dispatch({ type: EDIT_RESOURSE_UPDATE_FORM, value }),
    renderPdf: (value) => dispatch({ type: RENDER_PDF, value }),
    deleteAnexo: (value) => dispatch({ type: DELETE_ANEXO, value }),
    deleteFirma: (value) => dispatch({ type: DELETE_FIRMA, value }),
    initReload: (value) => dispatch({ type: RELOAD_PDF, value }),
    getFirebaseUserSaga: (value) => dispatch({ type: GET_FIREBASE_USER_SESSION, value }),
    uploadAnexosResources: (value) => dispatch({ type: ACTIONS_UPLOAD_ANEXOS_RESORSES, value }),
    actionFrameVideo: (value) => dispatch({ type: ACTIONS_FROM_FRAME_VIDEO, value }),
    closeModalMessageAlert: (value) => dispatch({ type: CLOSE_MODAL_MESSAGE_ALERT, value }),
    manageValidFileType: (value) => dispatch({ type: MANAGE_FILE_TYPE_VALID, value }),
    //actualizacion desde aquiUPDATE_FORMULARIO_TYPE,value
    updateTypeForm: (value) => dispatch({ type: UPDATE_FORMULARIO_TYPE, value }),
    closeModalInstruccion: (value) => dispatch({ type: OPEN_MODAL_INSTRUCCION_FORM, value }),
    gestionaConfirmaInstruccion: (value) => dispatch({ type: GESTIONA_MODAL_INSTRUCCION, value }),
    cleanFormRecursos: (value) => dispatch({ type: CLEAN_SECTION_RECURSOS, value }),
    activateFormBandeja: (value) => dispatch({ type: ACTIVATE_FORM_BANDEJA, value }),
    saveUserInfoBandeja: (value) => dispatch({ type: SAVE_USER_INFO_BANDEJA, value })
  };
};

export default connect(mapStateToProps, mapDispachToProps)(ComponentLargeForm);

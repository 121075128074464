import { takeLatest, put, call, select } from "redux-saga/effects";

import {
    INIT_ACTION,
} from './constants';

import {
    newActionSuccess,
} from './actions';


import { firebaseDatabase } from "../../controller/firebase";

function* sagaFunction(action) {
    const { value } = action;
    /*se evalua para saber que tipo de pdf se debe generar
        1. nuevopdf, corresponde a crear un pdf con los datos de c4c es decir una actualizacion
        2. Vivienda, corresponde a crear un pdf con los datos del formulario de postulación
        3. deportes, corresponde a crear un pdf con los datos de academia deportiva.
        si se crean mas pdf aqui se define a que coleccion apuntar
    */
    if (value.formname === "nuevopdf") {
        const data = localStorage.getItem('items')
        let opc = JSON.parse(data);        
        opc.formname = "vivienda";
        yield put(newActionSuccess(opc));
    } else {
        let solicitudData = false;
        if (value.formname === "deportes") {
            const solicitudes = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_DEPORTES).doc(value.formid).get();
            solicitudData = solicitudes.data();
            solicitudData.formname = "deportes";
        }
        if (value.formname === "vivienda") {
            const solicitudes = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).doc(value.formid).get();
            solicitudData = solicitudes.data();
            solicitudData.formname = "vivienda";
        }
        if (value.formname === "actualizacionVivienda") {
            const solicitudes = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA).doc(value.formid).get();
            solicitudData = solicitudes.data();
            solicitudData.formname = "actualizacionVivienda";
        }
        if (solicitudData) {
            yield put(newActionSuccess(solicitudData));
        }
    }



}

export function* watchComponent() {
    yield takeLatest(INIT_ACTION, sagaFunction);
}

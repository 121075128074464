import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createContext } from "react";
import { saveInSession } from '../../controller/session';
import { Button, ButtonGroup } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@material-ui/core/Modal';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import './auth.css';
import { Redirect } from "react-router-dom";

export function salirForm(logout) {
  window.close();
  logout();
  return;
}

function renderForm(callbackPath, urlRedirect, children, logout) {
  return (
    <div className="auth-wrapper">
      <Redirect to={callbackPath}></Redirect>
      <div className="button-nav-bandeja">
        <ButtonGroup color="primary" aria-label="primary button group">
          <Button onClick={() => salirForm(logout)}>
            <ExitToAppIcon />
          </Button>
        </ButtonGroup>
      </div>
      {
        children
      }

    </div>
  );
}



function LoginButton({ urlRedirect, callbackPath, children }) {

  const Auth0Context = createContext();
  const { isLoading, isAuthenticated, user, logout, loginWithRedirect } = useAuth0();


  if (isLoading) {
    return (
      <div className="loading-wrapper">
        <LinearProgress />
        <div>
          Cargando información del usuario ...
        </div>
      </div>
    );
  }

  if (isAuthenticated) {
    if (user && user.email)
      saveInSession('authUserColaborador', user);
    return renderForm(callbackPath, urlRedirect, children, logout);
  } else {
    sessionStorage.clear();
    loginWithRedirect();
  }
}

export default LoginButton;
import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ImageIcon from "@material-ui/icons/Image";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DescriptionIcon from "@material-ui/icons/Description";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Modal from "@material-ui/core/Modal";
import { getHistoryBandeja, getIdFormulario, getMessageAlert, getMessageConfirmation, getMessageHistory, getOpenModalCharged, getOpenSeeAnexos, getUserInfo } from "./selectors";
import { CLOSE_MODAL_ANEXOS, CLOSE_MODALES, RETURN_HOME_BANDEJA, SAVED_ACTIONS_VALIDATION, VISUALIZE_ANEXOS } from "./constants";
import { ComponetComercial } from "./componetComercial";
import ViviendaPage from "../vivienda";

function Componet() {
  const dispatch = useDispatch();


  const [itemsForm, setItemsForm] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [stepForm, setStepForm] = React.useState(0);
  const [stepFinal, setStepFinal] = React.useState(false);
  const [anexos, setAnexos] = React.useState([]);
  const [formCharged, setFormCharged] = React.useState(false);
  const [catalog, setCatalog] = React.useState(false);

  const openSeeAnexos = useSelector(getOpenSeeAnexos);
  const openModalCharged = useSelector(getOpenModalCharged);
  const idFormulario = useSelector(getIdFormulario);
  const messageAlert = useSelector(getMessageAlert);
  const messageConfirmation = useSelector(getMessageConfirmation);
  const userInfoBandeja = useSelector(getUserInfo);
  const historyBandeja = useSelector(getHistoryBandeja);
  const messageHistory = useSelector(getMessageHistory);

  const handleSeeAnexo = (value) => dispatch({ type: VISUALIZE_ANEXOS, value });
  const closeModalAdjuntos = (value) => dispatch({ type: CLOSE_MODAL_ANEXOS, value });
  const closeModales = (value) => dispatch({ type: CLOSE_MODALES, value });
  const savedActionsValidation = (value) => dispatch({ type: SAVED_ACTIONS_VALIDATION, value });
  const returnToHome = (value) => dispatch({ type: RETURN_HOME_BANDEJA, value });

  const modalSeeAdjuntos = () => {
    return (
      <React.Fragment>
        <div className="modalSeeAdjuntos">
          <div className="Anexo">
            {openSeeAnexos.type.includes("pdf") ? (
              <embed
                className="objAnexo"
                src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${openSeeAnexos.refFile}`}
                width="100%"
              />
            ) : (
              <img
                className="objAnexo"
                src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${openSeeAnexos.refFile}`}
              />
            )}
          </div>
        </div>
        <ButtonGroup color="primary" aria-label="primary button group">
          <Button
            className="buttonCloseAnexo"
            onClick={() => closeModalAdjuntos()}
          >
            X
          </Button>
        </ButtonGroup>
      </React.Fragment>
    );
  };

  const modalHistory = () => {
    return (
      <Dialog
        className="modal-history"
        open
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">HISTORIAL DE VALIDACIONES</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
          >
            {
              historyBandeja.map((r) => (
                <div className="row-history">
                  <h3><p>Fecha: </p>{r.date}</h3>
                  <div>
                    <h3><p>Realizado por: </p>{r.tomado}</h3>
                    {r.tomado.includes("Empresa") || r.tomado.includes("Usuario") ?
                      <React.Fragment>
                        <h2>Empresa</h2>
                        {r.name ? <h3><p>Nombre: </p>{r.name}</h3> : ""}
                        {r.email ? <h3><p>Correo electrónico: </p>{r.email}</h3> : ""}
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <h2>Asesor</h2>
                        <h3><p>Nombre: </p>{r.name}</h3>
                        <h3><p>Correo electrónico: </p>{r.email}</h3>
                      </React.Fragment>
                    }
                  </div>
                  <div>
                    <h2>Validación</h2>
                    <h3><p>Accion realizada: </p>{r.action}</h3>
                    {r.motivo ? <h3><p>Motivo: </p>{r.motivo}</h3> : ""}
                    {r.observation ? <h3><p>Observación: </p>{r.observation}</h3> : ""}
                    {r.refFile ? <h3><p>Referencia del archivo: </p>{r.refFile}</h3> : ""}
                  </div>
                </div>
              ))
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup color="primary" aria-label="primary button group">
            <Button
              onClick={() => closeModales()}
            >
              Cerrar
            </Button>
          </ButtonGroup>

        </DialogActions>
      </Dialog>
    );
  }

  const modalEsperaCharged = () => {
    return (
      <Modal disablePortal disableEnforceFocus disableAutoFocus open>
        <div className="modalEsperaCharged">
          <CircularProgress style={{ width: "70px", height: "70px" }} />
        </div>
      </Modal>
    );
  };

  const modalSubmitValid = () => {

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
      >
        <div className="messageSucess">
          <React.Fragment>
            {messageAlert.error ? <Alert className="alert danger">
              <p>{messageAlert.message}</p>
            </Alert>
              :
              <Alert className="alertsucessBorrador" >
                <p>{messageAlert.message}</p>
              </Alert>
            }
          </React.Fragment>


          <div>
            <Button
              className='buttonFinalizar'
              color="default"
              onClick={messageAlert.home ? () => returnToHome() : () => closeModales()}
            >
              Aceptar
            </Button>
          </div>


        </div>
      </Modal>
    );
  }

  const modalConfirmacion = () => {
    return (
      <div >
        <Dialog
          className="modal-confirmation"
          open
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Información</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
            //tabIndex={-1}
            >
              {messageConfirmation.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup color="primary" aria-label="primary button group">
              <Button
                onClick={() => closeModales()}
              >
                Cancelar
              </Button>


              <Button onClick={() => savedActionsValidation({ messageConfirmation, anexos, itemsForm, catalogo: catalog })}>
                Aceptar
              </Button>

            </ButtonGroup>

          </DialogActions>
        </Dialog>
      </div>
    );
  }


  return (
    <React.Fragment>
      {messageAlert ? modalSubmitValid() : ""}
      {openModalCharged ? modalEsperaCharged() : ""}
      {messageConfirmation && modalConfirmacion()}
      {messageHistory ? modalHistory() : ""}
      {anexos.length > 0 ? (
        <div className="container-form-anexos">
          <TableContainer
            component={Paper}
            className={`tableSeeAnexo ${openSeeAnexos ? "table-Container-2" : "table-Container"
              }`}
          >
            <Table className={"classes.table"} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="right">{"Anexos"}</TableCell> */}
                  <Button
                    color={"secondary"}
                    type={"submit"}
                    className={"buttonSeeAnexos"}
                    startIcon={<AttachFileIcon />}
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    Anexos
                  </Button>
                </TableRow>
              </TableHead>
              <TableBody>
                {collapsed &&
                  anexos.map((row) => (
                    <React.Fragment>
                      {(row.classification === "anexoUnificado" || row.classification === "formularioAfiliacion") &&
                        <TableRow>
                          <TableCell align="right">
                            <Button
                              color={"secondary"}
                              type={"submit"}
                              className={"buttonSeeAnexos"}
                              startIcon={
                                row.type.includes("pdf") ? (
                                  <DescriptionIcon />
                                ) : (
                                  <ImageIcon />
                                )
                              }
                              onClick={(event) => handleSeeAnexo({ event, row })}
                            >
                              {row.nameDoc ? row.nameDoc : row.nameArchivo}
                            </Button>
                          </TableCell>
                        </TableRow>
                      }
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="container-anexos-form">
            {openSeeAnexos !== false ? modalSeeAdjuntos() : ""}
          </div>
        </div>
      ) : (
        <h1>No hay anexos</h1>
      )}

      <div className="container-form-bandeja">
        <ViviendaPage
          idFormulario={idFormulario}
          formCharged={formCharged}
          setFormCharged={setFormCharged}
          setAnexos={setAnexos}
          stepForm={stepForm}
          setStepForm={setStepForm}
          stepFinal={stepFinal}
          setStepFinal={setStepFinal}
          userInfoBandeja={userInfoBandeja}
          setItemsForm={setItemsForm}
          setCatalog={setCatalog}
        />
      </div>

      <ComponetComercial
        stepForm={stepForm}
        setStepForm={setStepForm}
        stepFinal={stepFinal}
      />
    </React.Fragment>
  );
}

export const ComponetBandeja = connect(null, null)(Componet);
